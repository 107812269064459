/*
  ERROR PAGE TEMPLATE
  A template to display 404 pages
*/

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import PageLayout from '@base/layout/page-layout';
import Button from '@base/parts/button';

import { getGeneralData } from '@api/general-data';
import Logo from '@elements/logo';

function ErrorPage({ pageContext }) {
  const browser = typeof window !== 'undefined' && window;
  if (!pageContext || !browser) {
    return null;
  }

  const data = {
    pageData: {
      ...pageContext.content,
    },
  };

  // Setup Page specific data
  const generalData = getGeneralData(pageContext, data);
  const minifiedPageContext = generalData.pageContext;
  const { globalData } = generalData;
  const { modalData } = generalData;
  const title = data?.pageData?.title;
  const parentPage = null;
  const disablePageBreadcrumbs = true;

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: {
          back: globalData.stringsActions.back,
          home: 'Home',
        },
      }}
    >
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className=' flex flex-col items-center pb-32 px-12'
      >
        <Logo globalData={globalData} className='scale-75' />
        <h1
          className='text-white mb-12 text-xl lg:text-xxxl text-center'
        >
          {title || 'Not found'}
        </h1>
        <p className='text-center font-body text-white text-base'>
          {data?.pageData?.text
            ? data?.pageData?.text
            : 'The page you are looking for does not exist or has been moved.'}
        </p>
        <Button
          link={pageContext.locale !== 'en' ? `/${pageContext.locale}` : '/'}
          trigger='internalLink'
          size='lg'
          pageContext={pageContext}
          className='mt-8 lg:mt-16 button-secondary'
        >
          <div className='btn-content'>
            <i className='btn-decorator btn-decorator-left' />
            <span>
              {data?.pageData?.button
                ? data?.pageData?.button
                : 'Back to home page'}
            </span>
            <i className='btn-decorator btn-decorator-right' />
          </div>
        </Button>
      </motion.section>
      <StaticImage
        src='../../assets/img/backgrounds/modal-background.jpg'
        alt=''
        placeholder='blurred'
        loading='lazy'
        className='section-background gatsby-absolute'
        role='presentation'
      />
    </PageLayout>
  );
}

export default ErrorPage;

export { Head } from '@base/layout/head';
